import {
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
} from 'firebase/firestore';

class BlogPostClass {
  constructor(
    readonly title: string,
    readonly subtitle: string,
    readonly tagline: string,
    readonly featuredImageUrl: string,
    readonly featuredImageCaption: string,
    readonly slug: string,
    readonly publishedAt: Timestamp,
    readonly translationGroupId: string,
    readonly translationSlugs: Record<string, string>,
    readonly body?: string,
    readonly id?: string
  ) {}

  static cloneWithoutId(activity: BlogPost): BlogPost {
    const clonedBlogPost = { ...activity };
    delete clonedBlogPost['id'];
    return clonedBlogPost;
  }
}

export type BlogPost = BlogPostClass;

export const blogPostConverter = {
  toFirestore(activity: BlogPost): BlogPost {
    return BlogPostClass.cloneWithoutId(activity);
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<BlogPost>,
    options: SnapshotOptions
  ): BlogPost {
    const data = snapshot.data(options)!;
    return new BlogPostClass(
      data.title,
      data.subtitle,
      data.tagline,
      data.featuredImageUrl,
      data.featuredImageCaption,
      data.slug,
      data.publishedAt,
      data.translationGroupId,
      data.translationSlugs,
      data.body,
      data.id
    );
  },
};
