import { Link } from 'react-router-dom';
import scissors from './icons/scissors.svg';
import styles from './NavigationBar.module.css';
import NavButton from './NavButton';
import LanguageSwitcher from './LanguageSwitcher';

export default function NavigationBar() {
  return (
    <header className={styles.header} id="header">
      <nav className={styles.nav}>
        <div className={styles.home}>
          <Link to="/">
            <img src={scissors} alt="Logo Home" />
            Kids Activities
          </Link>
        </div>
        <div className={styles.otherNavigation}>
          <NavButton type="blog" color="dark" />
          <NavButton type="venues" color="dark" />
          <LanguageSwitcher />
        </div>
      </nav>
    </header>
  );
}
