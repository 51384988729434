import { Link } from 'react-router-dom';
import styles from './NavButton.module.css';
import blogIcon from './icons/bx-news.svg';
import venuesIcon from './icons/bx-map-pin.svg';
import { TFunction } from 'i18next/typescript/t';
import { useTranslation } from 'react-i18next';

interface NavButtonType {
  icon: string;
  alt: string;
  route: string;
  title: string;
}

function createButtonTypes(t: TFunction): Record<string, NavButtonType> {
  return {
    blog: {
      icon: blogIcon,
      alt: 'News paper',
      route: 'blog',
      title: 'Blog',
    },
    venues: {
      icon: venuesIcon,
      alt: 'Map pin',
      route: 'venues',
      title: t('venues'),
    },
  };
}

const COLORS = {
  light: { color: '#fa6325', backgroundColor: '#fa6325' },
  dark: { color: '#1e574f', backgroundColor: '#3a9b92' },
} as const;

type ButtonTypeKey = keyof ReturnType<typeof createButtonTypes>;
type ButtonColorKey = keyof typeof COLORS;

interface NavButtonProps {
  type: ButtonTypeKey;
  color: ButtonColorKey;
}

export default function NavButton({ type, color }: NavButtonProps) {
  const { t } = useTranslation();
  const allButtonTypes = createButtonTypes(t);
  const buttonType = allButtonTypes[type];
  const buttonColor = COLORS[color];

  return (
    <Link
      className={styles.navIcon}
      style={{ color: buttonColor.color }}
      to={buttonType.route}
    >
      <img
        className={styles.navIconImage}
        style={{ background: buttonColor.backgroundColor }}
        src={buttonType.icon}
        alt={buttonType.alt}
      />
      <span>{buttonType.title}</span>
    </Link>
  );
}
