import { useTranslation } from 'react-i18next';
import styles from './Error.module.css';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import NavigationBar from './navigation/NavigationBar';

export default function Error() {
  const { t } = useTranslation();
  const error = useRouteError();

  addRobotsTag();

  if (isRouteErrorResponse(error) && error.status === 404) {
    return (
      <>
        <NavigationBar />
        <div className={styles.container}>
          <h1>{t(error.data.titleKey)}</h1>
          <p>{t(error.data.descriptionKey)}</p>
        </div>
      </>
    );
  }

  return (
    <>
      <NavigationBar />
      <div className={styles.container}>
        <h1>{t('errorDefaultTitle')}</h1>
        <p>{t('errorDefaultDescription')}</p>
      </div>
    </>
  );
}

export function removeRobotsTag() {
  const metaTag = document.querySelector(
    'meta[name="robots"][content="noindex, follow"]'
  );
  if (metaTag) {
    metaTag.remove();
  }
}

export function addRobotsTag() {
  let metaTag = document.querySelector(
    'meta[name="robots"][content="noindex, follow"]'
  );

  if (!metaTag) {
    metaTag = document.createElement('meta');
    metaTag.setAttribute('name', 'robots');
    metaTag.setAttribute('content', 'noindex, follow');
    document.head.appendChild(metaTag);
  }
}
