import React, { useEffect, useRef, useState } from 'react';
import styles from './LanguageSwitcher.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import i18n from '../i18n';

// Define a Language interface with code, label, and icon (React element)
interface Language {
  code: string;
  label: string;
  icon: JSX.Element;
}

const enIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 24"
    width="36"
    height="24"
    aria-label="Union Jack Flag"
  >
    <rect width="36" height="24" fill="#012169" />
    <path d="M0 0l36 24m0-24L0 24" stroke="#FFF" strokeWidth="6" />
    <path d="M0 0l36 24m0-24L0 24" stroke="#C8102E" strokeWidth="4" />
    <path d="M18 0v24M0 12h36" stroke="#FFF" strokeWidth="8" />
    <path d="M18 0v24M0 12h36" stroke="#C8102E" strokeWidth="4" />
  </svg>
);

const deIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 24"
    width="36"
    height="24"
    aria-label="German Flag"
  >
    <rect width="36" height="8" fill="#000" />
    <rect y="8" width="36" height="8" fill="#D00" />
    <rect y="16" width="36" height="8" fill="#FFCE00" />
  </svg>
);

const availableLanguages: Language[] = [
  { code: 'en', label: 'English', icon: enIcon },
  { code: 'de', label: 'German', icon: deIcon },
];

const langToInfo = new Map<string, Language>(
  availableLanguages.map(langInfo => [langInfo.code, langInfo])
);

export default function LanguageSwitcher() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const lang = pathname.substring(1, 3);

  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggleDropdown = () => {
    setIsOpen(prev => !prev);
  };

  const selectLanguage = (language: Language) => {
    navigate(pathname.replace(lang, language.code));
    i18n.changeLanguage(language.code);
    setIsOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.languageSwitcher} ref={dropdownRef}>
      <button className={styles.button} onClick={handleToggleDropdown}>
        <span className={styles.iconContainer}>
          {langToInfo.get(lang)?.icon}
        </span>
        <svg
          className={`${styles.arrowIcon} ${isOpen ? styles.arrowIconRotated : ''}`}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="6 9 12 15 18 9"></polyline>
        </svg>
      </button>

      {isOpen && (
        <ul className={styles.dropdown}>
          {availableLanguages.map(language => (
            <li
              key={language.code}
              className={
                language.code === lang
                  ? styles.dropdownItemSelected
                  : styles.dropdownItem
              }
              onClick={() => selectLanguage(language)}
            >
              <span className={styles.dropdownIcon}>{language.icon}</span>
              <span className={styles.dropdownLabel}>{language.label}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
