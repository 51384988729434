import React, { useRef } from 'react';
import { Await, useLoaderData, useNavigate, useParams } from 'react-router-dom';
import './BlogPost.css';
import { BlogPost as BlogPostType } from './BlogPostType';
import { Suspense, useEffect } from 'react';
import Spinner from '../Spinner';

export default function BlogPost() {
  const params = useParams();
  const lang: string = params.lang || 'en';
  const navigate = useNavigate();
  const data = useLoaderData() as { blogPost: Promise<BlogPostType> };
  const langRef = useRef(lang);
  const translationSlugsRef = useRef<Record<string, string>>({});

  useEffect(() => {
    if (langRef.current && langRef.current != lang) {
      const translatedBlogPostRoute: string = `/${lang}/blog/${translationSlugsRef.current[lang]}`;
      navigate(translatedBlogPostRoute, { replace: true });
    }
    langRef.current = lang;
  }, [lang]);

  return (
    <div className="blog-post-container">
      <Suspense fallback={<Spinner />}>
        <Await
          resolve={data.blogPost}
          errorElement={<p>Error while loading!</p>}
        >
          {blogPost => {
            translationSlugsRef.current = blogPost.translationSlugs;

            return (
              <article className="blog-post">
                <section className="blog-post-header">
                  <h1>{blogPost?.title}</h1>
                  <h2>{blogPost?.subtitle}</h2>
                </section>

                <div className="blog-post-body">
                  <figure>
                    <img
                      src={blogPost?.featuredImageUrl}
                      alt={blogPost?.featuredImageCaption}
                    />
                  </figure>

                  <div
                    className="blog-post-content"
                    dangerouslySetInnerHTML={{ __html: blogPost?.body ?? '' }}
                  />
                </div>
              </article>
            );
          }}
        </Await>
      </Suspense>
    </div>
  );
}
